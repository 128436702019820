export default function ResourcesCard(el, {
    cardButtonHandle,
    mediaModalMarkup,
    actions,
    events,
}) {
    const cardButton = el.querySelector(cardButtonHandle);

	const toggleMp4 = () => {
		const video = document.querySelector('.modal .background-image > video');
		if (video) {
			if (video.paused) {
				video.play();
			} else {
				video.pause();
			}
		}
	}

	const loadMediaModal = () => {
        events.emit(actions.loadModal, { markup: mediaModalMarkup });
		toggleMp4();
    };

	cardButton && cardButton.addEventListener('click', () => {
		loadMediaModal();
	});

	events.on(actions.closeModal, () => toggleMp4());
}