export default function Main(el, {
    lockedClass,
    actions,
    events,
}) {
    function handleLockScroll() {
		document.body.classList.add(lockedClass);
    }
    function handleUnlockScroll() {
		document.body.classList.remove(lockedClass);
    }

    // Add event listeners
    events.on(actions.lockScroll, handleLockScroll);
    events.on(actions.unlockScroll, handleUnlockScroll);
}
