import Swiper from 'swiper';
import { Navigation, Scrollbar } from 'swiper/modules';

export default function TimelineCarousel(el, {
    carouselContHandle,
    controlsHandle,
    scrollbarClass,
}) {
    const cont = el.querySelector(carouselContHandle);
    const controls = el.querySelectorAll(controlsHandle);

    let next = [];
    let prev = [];

    controls.forEach(c => {
        const nextButton = c.querySelector('.swiper-button-next');
        const prevButton = c.querySelector('.swiper-button-prev');
        next.push(nextButton);
        prev.push(prevButton);
    });

    new Swiper(cont, { // eslint-disable-line no-new
        modules: [Navigation, Scrollbar],
        navigation: {
            nextEl: next,
            prevEl: prev,
        },
        scrollbar: {
			el: `.${scrollbarClass}`,
			draggable: true,
			hide: false,
		},
        slidesPerView: 'auto',
        spaceBetween: 10,
        initialSlide: 0,
        centeredSlides: false,
        breakpoints: {
			992: {
				spaceBetween: 30,
			},
            1280: {
                spaceBetween: 75,
            },
            1500: {
                spaceBetween: 125,
            }
		},
    });
}