export default function AvailableColors(el, {
    contentHandle,
    buttonToggleHandle,
}) {
    const main = el.querySelector(contentHandle);
    const buttonToggle = main.querySelector(buttonToggleHandle);

    // Available Colors show more/less
    if (buttonToggle) {
        buttonToggle.onclick = e => {
            e.preventDefault();

            const ul = document.querySelector('ul.colors');
            const hiddenColors = ul.querySelectorAll('li[style="display: none;"]');

            hiddenColors.forEach(i => { i.removeAttribute('style'); });

            if (hiddenColors.length > 0) {
                buttonToggle.textContent = 'Show Less';
            } else {
                const allColors = ul.querySelectorAll('li');
                allColors.forEach((color, i) => {
                    if (i >= 15) { color.style.display = 'none'; }
                });
                buttonToggle.textContent = 'Show More';
            }
        }
    }
}