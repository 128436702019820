import YouTubePlayer from 'youtube-player';

export default function VideoYoutube(el, {
    contHandle,
    playButtonHandle,
    videoId,
	actions,
    events,
}) {
    const main = el.querySelector(contHandle);
    const playButton = main.querySelector(playButtonHandle);
    const player = main.querySelector('div');
    const youtubePlayer = YouTubePlayer(player, {
        videoId: videoId,
        playerVars: {
            autoplay: 1,
        },
    });

    function handlePlayButton(e) {
        e.preventDefault();
        youtubePlayer.playVideo();
    }

    playButton.addEventListener('click', handlePlayButton);

	events.on(actions.carouselSlideChange, () => youtubePlayer.pauseVideo())
	events.on(actions.closeModal, () => youtubePlayer.pauseVideo());
}