export default function Listing(el, {
    listingWrapperHandle,
    filtersContHandle,
    filtersContWrapperHandle,
    resetButtonHandle,
    dropdownHandle,
    dropdownOptionsHandle,
    dropdownOptionHandle,
    actions,
    events,
}) {
    const main = el.querySelector(listingWrapperHandle);
    const filtersCont = main.querySelector(filtersContHandle);
    const filtersContWrapper = filtersCont.querySelector(filtersContWrapperHandle);
    const reset = filtersCont.querySelectorAll(resetButtonHandle);
    const form = filtersCont.querySelector('form');
    const dropdowns = form.querySelectorAll(dropdownHandle);
    const dropdownOptions = form.querySelectorAll(dropdownOptionsHandle);
    const filters = form.querySelectorAll('label > input[name]');

    const toggleFilters = () => {
        filtersContWrapper.classList.toggle('active');
    };

    function handleStyles() {
        if (window.innerWidth >= 1280) {
            dropdownOptions.forEach(d => {
                d.style.display = 'none';
            });

            dropdowns.forEach(d => {
                d.classList.remove('active');

                d.onclick = e => {
                    const options = d.querySelector(dropdownOptionsHandle);
                    e.currentTarget.classList.toggle('active');
                    options.style.display = (options.style.display === 'flex') ? 'none' : 'flex';
                };
            });
        } else {
            dropdownOptions.forEach(d => {
                d.style.display = 'flex';
            });

            dropdowns.forEach(d => {
                d.onclick = null;
            });
        }
    }

    function handleFilter({ currentTarget }) {
        const dropdown = currentTarget.closest(dropdownHandle);
        const activeFilter = currentTarget.nextElementSibling;
        const adjacentLabels = dropdown.querySelectorAll(dropdownOptionHandle);
        const adjacentInputs = dropdown.querySelectorAll(`${dropdownOptionHandle} input`);

        adjacentLabels.forEach((l, i) => {
            const input = adjacentInputs[i];

            input.checked = input === currentTarget && currentTarget.checked;
            l.classList.toggle('highlight', l === activeFilter);
        });

        const formData = new FormData(form);

        events.emit(actions.filterListing, {
            color: formData.get('color') || '',
            style: formData.get('style') || '',
            material: formData.get('material') || '',
        });

        window.scrollTo({
            top: el.getBoundingClientRect().top + window.scrollY - 140,
            behavior: 'smooth',
        });
    }
    function handleReset() {
        const cards = main.querySelectorAll('.detail, .overview');
        const p = el.querySelector('p'); // empty result message

        p.classList.remove('visible');

        cards.forEach(c => {
            c.classList.add('visible');
        });

        dropdowns.forEach(dropdown => {
            const dropdownInputs = dropdown.querySelectorAll('input');
            const adjacentFilters = dropdown.querySelectorAll('label > span');

            dropdownInputs.forEach(input => {
                input.checked = false;
            });

            adjacentFilters.forEach(filter => {
                filter.parentElement.classList.remove('highlight');
            });
        });

        window.scrollTo({
            top: el.getBoundingClientRect().top + window.scrollY - 140,
            behavior: 'smooth',
        });
    }

    handleStyles();

    if (filters) {
        filters.forEach(f => {
            f.addEventListener('change', handleFilter);
        });
    }

    document.addEventListener('click', e => {
        if (e.target.matches('.listing__filters-wrapper > .listing__filters-heading > .mobile > button')) {
            e.preventDefault();
            toggleFilters(e.target);
        }
    });

    reset.forEach(r => {
        r.addEventListener('click', handleReset);
    });

    window.addEventListener('resize', handleStyles);
}
