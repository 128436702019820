export default function Resources(el, {
	resourcesAuth,
}) {
	if (resourcesAuth) {
		const navToggleBtn = el.querySelectorAll('.resources__nav > .resources__nav-wrapper > h3');
		const headerOffsetHeight = getComputedStyle(document.querySelector('.header__nav--mobile')).display === 'block' ? 0 : getComputedStyle(document.body).getPropertyValue('--header-desktop-height-slimline');
		const backToTopLinks = el.querySelectorAll('.back-to-top');
		const tabs = el.querySelector('.resources__tabs');
		const tabLinks = tabs.querySelectorAll('.tabs li a');
		const tabContent = tabs.querySelectorAll('.resources__tab-content-wrapper__content');
		const mq = window.matchMedia('(min-width: 1280px)');

		let anchorLinks = [];
		const removeActiveLinks = (links) => links.forEach(link => link.classList.remove('active'));

		const toggleNav = (btn) => {
			const navWrapper = btn.parentNode;
			navWrapper.classList.toggle('resources__nav--active');
			removeActiveLinks(anchorLinks);
		};

		const attachNavToggleListeners = () => {
			navToggleBtn.forEach((btn) => {
				btn.addEventListener('click', () => {
					toggleNav(btn);
				});
			});
		};

		const anchorScroll = (target) => {
			if (mq.matches) {
				window.scrollTo({
					top: target.offsetTop - parseInt(headerOffsetHeight, 10),
					behavior: 'smooth',
				});
			} else {
				window.scrollTo({
					top: target.offsetTop - 40,
					behavior: 'smooth',
				});
			}
		};

		const attachAnchorLinkListeners = (links) => {
			links.forEach((link) => {
				link.addEventListener('click', (e) => {
					e.preventDefault();
					removeActiveLinks(links);
					if (e.target && e.target.getAttribute('href')) {
						e.target.parentNode.classList.add('active');
						const targetId = e.target.getAttribute('href').substring(1);
						const target = document.getElementById(targetId);
						anchorScroll(target);
					}
				});
			});
		};

		// Initial attachment of anchor link listeners
		const updateAnchorLinks = () => {
			const activeTab = tabs.querySelector('.tabs li a.active');
			const activeTabContentId = activeTab.getAttribute('href').substring(1);
			const activeTabContent = document.getElementById(activeTabContentId);
			const newAnchorLinks = activeTabContent.querySelectorAll('.resources__nav-wrapper > div > ul > li');
			attachAnchorLinkListeners(newAnchorLinks); // Reattach listeners for the new active tab
		};

		// Function to handle tab click event
		const handleTabs = (tabLink) => {
			// Remove the "active" class from all tab links and tab content
			tabLinks.forEach(l => l.classList.remove('active'));
			tabContent.forEach(c => c.classList.remove('active'));

			// Add the "active" class to the clicked tab link and its corresponding content
			const targetId = tabLink.getAttribute('href').substring(1);
			const target = document.getElementById(targetId);

			if (target) {
				tabLink.classList.add('active');
				target.classList.add('active');
				updateAnchorLinks(); // Reattach listeners for the new active tab
			}
		};

		// Attach listeners for tab links
		tabLinks.forEach(t => {
			t.addEventListener('click', e => {
				e.preventDefault();
				handleTabs(t);
			});
		});

		const handleUrlHash = () => {
			const hash = window.location.hash;

			// Check if the hash matches any of the tab anchors
			tabLinks.forEach(t => {
				if (t.getAttribute('href') === hash) {
					handleTabs(t);

					// Unfortunately this needs the setTimeout
					setTimeout(() => {
						window.scrollTo({
							top: 0,
							behavior: 'smooth'
						});
					}, 100);
				}
			});
		};

		// Attach listeners for back to top links
		backToTopLinks.forEach((link) => {
			link.addEventListener('click', (e) => {
				e.preventDefault();
				const section = link.closest('section');
				const targetOffsetTop = section.offsetTop - 103;

				window.scrollTo({
					top: targetOffsetTop,
					behavior: 'smooth'
				});
			});
		});

		// Initial setup for anchor links and nav toggle listeners
		updateAnchorLinks();
		attachNavToggleListeners();

		window.addEventListener('load', handleUrlHash);
		window.addEventListener('hashchange', handleUrlHash);
	}
}