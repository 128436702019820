export default function Listing(el, {
    actions,
    events,
}) {
    const cards = el.querySelectorAll('.detail');
    const p = el.querySelector('p');

    events.on(actions.filterListing, ({ detail }) => {
        const { worksWith, material } = detail;

        cards.forEach(c => {
            const workswiths = c.dataset.workswiths.split(',');
            const materials = c.dataset.materials.split(',');

            const hasWorksWith = worksWith === '' || workswiths.includes(worksWith);
            const hasMaterial = material === '' || materials.includes(material);
            const cardMatches = hasWorksWith && hasMaterial;

            c.classList.toggle('visible', cardMatches);
        });

        const hasClass = Array.from(cards).some(c => c.classList.contains('visible'));

        if (hasClass) {
            p.classList.remove('visible');
        } else {
            p.classList.add('visible');
        }
    });
}
