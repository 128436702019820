export default function Form(el, {
    formHandle,
    siteKey,
    actions,
    events,
}) {
    const form = el.querySelector('form');
    const submit = form.querySelector('[type="submit"]');
    const successMessage = el.querySelector('form + div');
    const formName = encodeURIComponent(formHandle);
    const grc = grecaptcha; // eslint-disable-line no-undef

    submit.removeAttribute('disabled');

    form.onsubmit = e => {
        e.preventDefault();

        submit.setAttribute('disabled', 'true');

        const body = new FormData(form);

        grc.enterprise.ready(async () => {
            const token = await grc.enterprise.execute(siteKey, { action: 'SUBMIT' });

            body.append('token', token);

            fetch('/', {
                method: 'POST',
                headers: { Accept: 'application/json' },
                body,
            })
                .then(res => res.json().then(json => ({
                    status: res.status,
                    ...json,
                })))
                .then(({
                    status,
                    message = '',
                    errors = {},
                }) => {
                    // Reset all field errors to empty
                    Array.from(body.keys()).map(name => name.replace('[]', '')).forEach(name => {
                        events.emit(actions.showFieldError, { name, errors: [] });
                    });
                    submit.removeAttribute('disabled');

                    switch (status) {
                    case 500:
                        window.alert(message); // eslint-disable-line no-alert

                        break;
                    case 400:
                        Object.entries(errors).forEach(([name, errs]) => {
                            events.emit(actions.showFieldError, { name, errors: errs });
                        });

                        break;
                    case 200:
                    default:
                        form.remove();
                        successMessage.style.display = 'flex';
                        window.scrollTo({
                            top: el.getBoundingClientRect().top + window.scrollY - 160,
                            behavior: 'smooth',
                        });
                        window.history.pushState({}, '', `${window.location.pathname}?form=${formName}&success=true`);
                    }
                });
        });
    };
}
