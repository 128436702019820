import Swiper from 'swiper';
import { EffectFade, Navigation, Pagination } from 'swiper/modules';

export default function DetailsCarousel(el, {
	swiperHandleClass,
	swiperPagIndicatorsHandleClass,
	swiperPrevClass,
	swiperNextClass,
}) {
	const swiperHandle = el.querySelector(`.${swiperHandleClass}`);
	const swiperPrev = el.querySelector(`.${swiperPrevClass}`);
	const swiperNext = el.querySelector(`.${swiperNextClass}`);
	const paginationHandle = el.querySelector(`.${swiperPagIndicatorsHandleClass}`);
	const mq = window.matchMedia('(min-width: 1280px)');

	const swiper = new Swiper(swiperHandle, { // eslint-disable-line
		modules: [EffectFade, Navigation, Pagination],
		centeredSlides: true,
		slidesPerView: 'auto',
		effect: window.innerWidth >= 1280 ? 'fade' : 'slide',
		fadeEffect: {
			crossFade: true
		},
		spaceBetween: 5,
		navigation: {
			prevEl: swiperPrev,
			nextEl: swiperNext,
		},
		on: {
			init: function () {
				paginationHandle.onclick = (e) => {
					const indicator = e.target.closest('button');

					if (indicator) {
						const index = Array.from(indicator.parentNode.children).indexOf(indicator);

						this.slideTo(index);

						indicator.scrollIntoView({
							behavior: 'smooth',
							block: 'nearest',
							inline: 'nearest',
						});
					}
				};
			},
			slideChange: function () {
				const activeSlideIndex = this.activeIndex;
				const indicators = paginationHandle.querySelectorAll('button');

				indicators.forEach((indicator, index) => {
					const isActive = index === activeSlideIndex;

					indicator.classList.toggle('active', isActive);

					if (isActive) {
						indicator.scrollIntoView({
							behavior: 'smooth',
							block: 'nearest',
							inline: 'nearest',
						});
					}

					indicator.onclick = () => this.slideTo(index);
				});
			},
		}
    });

	function justifyAbsoluteContentCenter() {
		const container = paginationHandle.querySelector('div');
		const containerWidth = container.offsetWidth;
		const buttons = container.querySelectorAll('button');

		let buttonsWidth = 0;

		buttons.forEach(button => {
			buttonsWidth += button.offsetWidth; // add the width of each button
		});

		// compare container width with the total width of buttons and if our screen size is >1280px
		if (mq.matches) {
			const justifyContent = containerWidth > buttonsWidth ? 'center' : 'flex-start';
			container.style.justifyContent = justifyContent;
		}
	}

    window.addEventListener('load', justifyAbsoluteContentCenter);
    window.addEventListener('resize', justifyAbsoluteContentCenter);
}