import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

export default function InspirationGallery(el, {
    galleryHandle,
    sectionId,
    actions,
    events,
}) {
    const form = el.querySelector('form');
    const { product } = form;

    const desktopFilters = el.querySelector('.inspiration-gallery__filters--desktop');
    const mobileFilters = el.querySelector('.inspiration-gallery__filters--mobile');
    const mobileFilterOptionsBtn = el.querySelector('.inspiration-gallery__filters--mobile > button');

    const collection = desktopFilters.querySelector('.collections-wrapper-parent');
    const style = desktopFilters.querySelector('.styles-wrapper-parent');
    const color = desktopFilters.querySelector('.colors-wrapper-parent');
    const collectionMobile = mobileFilters.querySelector('.collections-wrapper-parent');
    const styleMobile = mobileFilters.querySelector('.styles-wrapper-parent');
    const colorMobile = mobileFilters.querySelector('.colors-wrapper-parent');
    const gallery = el.querySelector(galleryHandle);

    const desktopProductFilters = desktopFilters.querySelectorAll('.inspiration-gallery__filters-primary .primary-filter-label');
	const mobileProductFilters = mobileFilters.querySelectorAll('.products-wrapper-parent > ul > li');

	const selectedFiltersWrapperMobile = mobileFilters.querySelector('.inspiration-gallery__selected-filters-parent');

	const swiperHandle = el.querySelector('.inspiration-gallery__filters--desktop .inspiration-gallery__filters-primary');
	const swiperPrev = el.querySelector('.inspiration-gallery__nav-btn--prev');
	const swiperNext = el.querySelector('.inspiration-gallery__nav-btn--next');

	const galleryGrids = el.querySelectorAll('.inspiration-gallery-images__gallery-grid-wrapper:has(div)');
	const loadMoreBtn = el.querySelector('.inspiration-gallery__load-more');
	let gridsToShowArray = [0];

	const galleryProgressiveRender = (grids, btnClick = false, btnInstance = '') => {
		if (btnClick && btnInstance === 'pageLoad') {
			gridsToShowArray.push(gridsToShowArray[gridsToShowArray.length - 1] + 1);
		}

		if (grids.length > 1) {
			loadMoreBtn.disabled = false;
			loadMoreBtn.classList.add('active');

			grids.forEach((grid) => {
				if (gridsToShowArray.includes(parseInt(grid.dataset.gridIndex, 10))) {
					grid.classList.add('show-grid');
					grid.classList.remove('hide-grid');
				} else {
					grid.classList.add('hide-grid');
					grid.classList.remove('show-grid');
				}

				if (gridsToShowArray.length === grids.length) {
					loadMoreBtn.classList.remove('active');
					loadMoreBtn.disabled = true;
				}
			});
		} else {
			loadMoreBtn.classList.remove('active');
			loadMoreBtn.disabled = false;
		}
	}

	galleryProgressiveRender(galleryGrids);

	const toggleDesktopProductFilters = (filter) => {
		desktopProductFilters.forEach((filterElement) => {
			filterElement.classList.remove('product-filter-active');
			filterElement.classList.add('product-filter-inactive');
		});
		filter.classList.remove('product-filter-inactive');
		filter.classList.add('product-filter-active');
	}

	const toggleDesktopProductFiltersOnRefresh = () => {
		const urlParams = new URLSearchParams(window.location.search);
		const selectedProductFilterId = urlParams.get('product');
		if (selectedProductFilterId) {
			const selectedProductFilter = desktopFilters.querySelector(`input[name="product"][value="${selectedProductFilterId}"] + label`);
			toggleDesktopProductFilters(selectedProductFilter);
		}
	}

    function updateGallery(params, push = true) {
        fetch(`/json/inspiration-gallery?${params}`, {
            headers: { Accept: 'application/json' },
        }).then(res => res.json().then(json => ({
            status: res.status,
            ...json,
        }))).then(({
            status,
            collectionsLength,
            stylesLength,
            colorsLength,
            collectionOptions,
            styleOptions,
            colorOptions,
            collectionOptionsMobile,
            styleOptionsMobile,
            colorOptionsMobile,
			selectedFiltersMobile,
            displayImages,
        }) => {
            if (status !== 200) return;

            collection.innerHTML = collectionOptions;
            collection.style.display = collectionsLength > 1 ? 'block' : 'none';
            style.innerHTML = styleOptions;
            style.style.display = stylesLength > 0 ? 'block' : 'none';
            color.innerHTML = colorOptions;
            color.style.display = colorsLength > 0 ? 'block' : 'none';

            collectionMobile.innerHTML = collectionOptionsMobile;
            collectionMobile.style.display = collectionsLength > 0 ? 'block' : 'none';
            styleMobile.innerHTML = styleOptionsMobile;
            styleMobile.style.display = stylesLength > 0 ? 'block' : 'none';
            colorMobile.innerHTML = colorOptionsMobile;
            colorMobile.style.display = colorsLength > 0 ? 'block' : 'none';

			selectedFiltersWrapperMobile.innerHTML = selectedFiltersMobile;

            gallery.innerHTML = displayImages;

			const galleryGridsAjax = el.querySelectorAll('.inspiration-gallery-images__gallery-grid-wrapper:has(div)');
			gridsToShowArray = [0];

            setTimeout(() => {
                gallery.classList.add('remove-active');
            }, 500);
            setTimeout(() => {
				gallery.classList.remove('active', 'remove-active');
				galleryProgressiveRender(galleryGridsAjax);
				loadMoreBtn.addEventListener('click', () => galleryProgressiveRender(galleryGridsAjax, true, 'ajax'));
            }, 1000);

            if (push) {
                const paramsString = params.toString();

                window.history.pushState({ paramsString }, '', `?${params}`);
            }
        });
    }

    const toggleFilters = btn => {
        const filterMenus = desktopFilters.querySelectorAll('.inspiration-gallery__filters-options');

		if (btn.parentNode.classList.contains('inspiration-gallery__filters-options--active')) {
			btn.parentNode.classList.remove('inspiration-gallery__filters-options--active');
		} else {
			filterMenus.forEach(menu => menu.classList.remove('inspiration-gallery__filters-options--active'));
			btn.parentNode.classList.add('inspiration-gallery__filters-options--active');
		}
    };

	const toggleFiltersMobile = btn => {
        btn.parentNode.classList.toggle('inspiration-gallery__filters-options--active');
    };

    const setmobileProductFiltersActive = filter => {
        mobileProductFilters.forEach(li => li.classList.remove('inspiration-gallery__filters-option--active'));
        mobileProductFilters[filter.id.split('-')[1] - 1].classList.add('inspiration-gallery__filters-option--active');
    };

    const loadImageModal = params => {
        fetch(`/json/inspiration-gallery-carousel?${params}`, {
            headers: { Accept: 'application/json' },
        }).then(res => res.json().then(json => ({
            status: res.status,
            ...json,
        }))).then(({
            status,
            imageCarouselMarkup,
        }) => {
            if (status !== 200) return;

            events.emit(actions.loadModal, { markup: imageCarouselMarkup });
        });
    };

    window.addEventListener('popstate', ({ state }) => {
        const { paramsString } = state || {};
        const params = new URLSearchParams(paramsString);
        const activeProduct = params.get('product');

        product.value = activeProduct;
        setTimeout(() => {
            updateGallery(params);
        }, 500);
    });

    form.addEventListener('change', (event) => {
        const formData = new FormData(form);
        const params = new URLSearchParams(formData);

		if (event.target.name === 'product') {
			params.delete('collection');
			params.delete('style');
			params.delete('color');
		}

        gallery.classList.add('active');

        setTimeout(() => {
            updateGallery(params);
        }, 500);
    });

	form.addEventListener('reset', () => {
		const params = new URLSearchParams();

		gallery.classList.add('active');

        setTimeout(() => {
			mobileProductFilters.forEach(li => li.classList.remove('inspiration-gallery__filters-option--active'));
			updateGallery(params);
        }, 1000);
    });

    const paramsString = (new URLSearchParams(window.location.search)).toString();

    window.history.replaceState({ paramsString }, '', window.location.href);

    document.addEventListener('click', event => {
        if (event.target.matches('.inspiration-gallery__filters-options > button.show-filters')) {
            event.preventDefault();
            toggleFilters(event.target);
        }

		if (event.target.matches('.inspiration-gallery__filters-options > button.active-filter')) {
            const formData = new FormData(form);
			const params = new URLSearchParams(formData);

			const filterGroup = event.target.closest('.inspiration-gallery__filters-options').dataset.filterGroup;
			params.delete(filterGroup);

			gallery.classList.add('active');

			setTimeout(() => {
				updateGallery(params);
			}, 500);
        }

        if (event.target.matches('input[name="product"]')) {
            setmobileProductFiltersActive(event.target);
        }

        if (event.target.matches(`${galleryHandle} img`)) {
            const formData = new FormData(form);
            const params = new URLSearchParams(formData);

            params.set('activeImage', event.target.dataset.imageIndex);
            params.set('sectionId', sectionId);

            loadImageModal(params);
        }

		if (event.target.matches('.inspiration-gallery__selected-filters div')) {
            const formData = new FormData(form);
			const params = new URLSearchParams(formData);

			const filterGroup = event.target.dataset.filterGroup;

			params.delete(filterGroup);

			gallery.classList.add('active');

			setTimeout(() => {
				updateGallery(params);
			}, 500);
        }

		if (event.target.matches('.inspiration-gallery__filters-back-to-top a')) {
			window.scroll({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
		}

		if (event.target.matches('.inspiration-gallery__filters--desktop .primary-filter-remove')) {
			window.location.href = '/inspiration-gallery'; // sledgehammer out all the parameters! 🤭
		}
    });

	mobileFilterOptionsBtn.addEventListener('click', e => toggleFiltersMobile(e.target));

	loadMoreBtn.addEventListener('click', () => galleryProgressiveRender(galleryGrids, true, 'pageLoad'));

	desktopProductFilters.forEach((filter) => {
		filter.addEventListener('click', (e) => {
			toggleDesktopProductFilters(e.target.closest('label'));
		});
	});

	toggleDesktopProductFiltersOnRefresh();

	const swiper = new Swiper(swiperHandle, { // eslint-disable-line
		modules: [
			Navigation,
		],
		slidesPerView: 'auto',
		spaceBetween: 20,
		navigation: {
			prevEl: swiperPrev,
			nextEl: swiperNext,
		},
    });
}
