export default function Hero(el, {
	scrollBtnClass,
}) {
	const headerOffsetHeight = getComputedStyle(document.querySelector('.header__nav--mobile')).display === 'block' ? 0 : getComputedStyle(document.body).getPropertyValue('--header-desktop-height-slimline');
	const scrollBtn = el.querySelector(`.${scrollBtnClass}`);

	const scrollContent = () => {
		const scrollPosition = el.offsetHeight - parseInt(headerOffsetHeight, 10);
		window.scrollTo({top: scrollPosition, behavior: 'smooth'});
	};

	scrollBtn.addEventListener('click', () => scrollContent());
}