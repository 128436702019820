const toggleVisibility = (el, visible) => {
    el.style.display = visible ? 'block' : 'none';
    // Need to hide parent container for form column formatting
    el.parentElement.style.display = visible ? 'block' : 'none';
};

export default function FormField(el, {
    name,
    errorClass,
    conditionalName,
    conditionalValue,
    actions,
    events,
}) {
    const error = el.querySelector('p');

    // File upload (the below only handles single file uploads)
    const fileUpload = el.querySelector('input[type=file]');
    const instructions = el.querySelector('.instructions');
    const fileUploaded = el.querySelector('.file-uploaded');
    const fileUploadedContent = el.querySelector('.file-uploaded-content');
    const removeUpload = el.querySelector('.remove-upload');

    function handleFileUpload() {
        instructions.style.display = 'none';
        fileUploaded.style.display = 'flex';
        const fileName = fileUpload.files[0].name;
        const fileSize = fileUpload.files[0].size;

        let fileSizeFormatted;
        if (fileSize < 1024) {
            fileSizeFormatted = `${fileSize} B`;
        } else if (fileSize < 1024 * 1024) {
            const fileSizeKB = fileSize / 1024;
            fileSizeFormatted = `${fileSizeKB.toFixed(2)} KB`;
        } else {
            const fileSizeMB = fileSize / (1024 * 1024);
            fileSizeFormatted = `${fileSizeMB.toFixed(2)} MB`;
        }

        fileUploadedContent.innerHTML = `<div class="icon"></div><div class="file-specs"><h4>${fileName}</h4><p>${fileSizeFormatted}</p></div>`;
    }

    function handleFileUploadButton() {
        const fauxButton = el.querySelector('.faux-button');
        fauxButton.classList.toggle('hovered');
    }

    function handlefileUploadRemove() {
        document.getElementById('file').value = ''; // clear the file input value
        instructions.style.display = 'flex';
        fileUploaded.style.display = 'none';
    }

    events.on(actions.showFieldError, ({ detail }) => {
        if (detail.name !== name) return;

        el.classList.toggle(errorClass, detail.errors.length > 0);
        error.textContent = detail.errors.join(', ');
    });

    // Initalize conditional formatting
    if (conditionalName && conditionalValue) {
        const form = el.closest('form');
        const targets = form.querySelectorAll(`[name="${conditionalName}"]`);
        const formData = new FormData(form);

        targets.forEach(target => {
            target.addEventListener('change', e => {
                toggleVisibility(el, e.currentTarget.value === conditionalValue);
            });
        });

        toggleVisibility(el, formData.get(conditionalName) === conditionalValue);
    }

    // File upload field event listeners
    if (fileUpload) {
        fileUpload.addEventListener('mouseenter', handleFileUploadButton);
        fileUpload.addEventListener('mouseleave', handleFileUploadButton);
        fileUpload.addEventListener('change', handleFileUpload);
        removeUpload.addEventListener('click', handlefileUploadRemove);
    }
}
