import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

export default function TestimonialsCarousel(el, {
	swiperHandleClass,
	swiperNavMobileContainerClass,
	swiperNavDesktopContainerClass,
	swiperPrevClass,
	swiperNextClass,
}) {
	const swiperHandle = el.querySelector(`.${swiperHandleClass}`);
	const swiperNavMobileContainer = el.querySelector(`.${swiperNavMobileContainerClass}`);
	const swiperPrevMobile = swiperNavMobileContainer.querySelector(`.${swiperPrevClass}`);
	const swiperNextMobile = swiperNavMobileContainer.querySelector(`.${swiperNextClass}`);
	const swiperNavDesktopContainer = el.querySelector(`.${swiperNavDesktopContainerClass}`);
	const swiperPrevDesktop = swiperNavDesktopContainer.querySelector(`.${swiperPrevClass}`);
	const swiperNextDesktop = swiperNavDesktopContainer.querySelector(`.${swiperNextClass}`);

	const swiper = new Swiper(swiperHandle, { // eslint-disable-line
		modules: [
			Navigation,
		],
		navigation: {
			prevEl: swiperPrevMobile,
			nextEl: swiperNextMobile,
		},
		breakpoints: {
			1280: {
				navigation: {
					prevEl: swiperPrevDesktop,
					nextEl: swiperNextDesktop,
				},
			}
		},
    });
}