export default function Header(el, {
    mobileNavClass,
    mobileNavExpandedClass,
    mobileNavStickyClass,
    mobileNavMenuBtnClass,
    desktopNavClass,
    desktopNavPrimaryLinksClass,
    desktopNavTransparentClass,
    navLinkDropdownClass,
    navLinkDropdownSpanClass,
    navLinkExpandedClass,
    megaMenuClass,
    megaMenuExpandedClass,
    megaMenuLinkClass,
    megaMenuLinkActiveClass,
    megaMenuSublinksContentClass,
    megaMenuSublinksContentActiveClass,
    megaMenuSublinkClass,
    megaMenuSublinkActiveClass,
    megaMenuSublinksImgClass,
    megaMenuSublinksImgActiveClass,
    hasHeroClass,
    activeClass,
    events,
    actions,
}) {
    // Variables

    const mobileNav = el.querySelector(`.${mobileNavClass}`);
    const mobileNavMenuBtn = mobileNav.querySelectorAll(`.${mobileNavMenuBtnClass}`);
    const mobileNavLinkDropdown = mobileNav.querySelectorAll(`.${navLinkDropdownClass} > a`);
    const mobileNavLinkDropdownSpan = mobileNav.querySelectorAll(`.${navLinkDropdownSpanClass} > span`);
    const mobileNavHeaderHeight = getComputedStyle(document.body).getPropertyValue('--header-mobile-height');
    const mobileNavActive = getComputedStyle(mobileNav).display === 'block';

    const desktopNav = el.querySelector(`.${desktopNavClass}`);
    const desktopNavIsTransparent = desktopNav.classList.contains(desktopNavTransparentClass);
    const desktopNavLinkDropdown = desktopNav.querySelectorAll(`.${navLinkDropdownClass} > a`);
    const desktopNavActive = getComputedStyle(desktopNav).display === 'block';
    const desktopNavPrimaryLinks = desktopNav.querySelectorAll(`.${desktopNavPrimaryLinksClass} > a`);
	const desktopNavAuxLinksContainer = desktopNav.querySelector('.header__aux-nav');

    const megaMenus = desktopNav.querySelectorAll(`.${megaMenuClass}`);
    const megaMenuLinks = desktopNav.querySelectorAll(`.${megaMenuLinkClass}`);
    const megaMenuSublinksContent = desktopNav.querySelectorAll(`.${megaMenuSublinksContentClass}`);
    const megaMenuSublinks = desktopNav.querySelectorAll(`.${megaMenuSublinkClass}`);

    let scrollYPos = window.scrollY;

    // ******************************

    // Event handler functions

    const handleEscape = ({ keyCode }) => {
        // only care about escape key
        if (keyCode !== 27) return;

        megaMenus.forEach(menu => {
            menu.classList.remove(megaMenuExpandedClass);
        });
        desktopNavLinkDropdown.forEach(desktopNavLink => {
            desktopNavLink.parentNode.classList.remove(navLinkExpandedClass);
        });
        if (desktopNavIsTransparent) {
            desktopNav.classList.toggle('is-fixed', window.scrollY !== 0);
            desktopNav.classList.toggle(desktopNavTransparentClass, window.scrollY === 0);
        }
        events.emit(actions.unlockScroll);
    };

    desktopNavPrimaryLinks.forEach(link => {
        /**
         * Check if the current URL matches the href of the navigation item exactly,
         * or if the current URL with a '#' appended matches the href of the navigation item.
         */
        if (window.location.href === link.href || (`${window.location.href}#`) === link.href) {
            link.classList.toggle(activeClass);
        }

        /**
         * Check if the current URL starts with the href of the navigation item followed
         * by a forward slash. This is used to match URLs with an additional segment after
         * the primary navigation item.
         */
        if (window.location.href.startsWith(`${link.href}/`)) {
            link.classList.toggle(activeClass);
        }
    });

    const toggleMobileNav = () => mobileNav.classList.toggle(mobileNavExpandedClass);
    const toggleMobileNavLinks = link => link.classList.toggle(navLinkExpandedClass);

    const toggleMobileNavHeaderSticky = () => {
        if (window.scrollY < scrollYPos) {
            if (window.scrollY > parseInt(mobileNavHeaderHeight, 10)) {
                mobileNav.classList.add(mobileNavStickyClass);
                mobileNav.style.top = 0;
                mobileNav.style.position = 'fixed';
            }
            scrollYPos = window.scrollY;
        } else {
            if (window.scrollY > parseInt(mobileNavHeaderHeight, 10)) {
                mobileNav.classList.remove(mobileNavStickyClass);
                mobileNav.style.top = `-${mobileNavHeaderHeight}`;
            }
            scrollYPos = window.scrollY;
        }

        if (window.scrollY === 0) {
            mobileNav.classList.remove(mobileNavStickyClass);
            mobileNav.style.position = 'absolute';
        }
    };

    const setMobileNavHeaderSticky = () => {
        // if user refreshes the page when scrolled down past nav
        // needs to go in a setTimeout otherwise iOS always returns window.scrollY as 0
        setTimeout(() => {
            if (window.scrollY > parseInt(mobileNavHeaderHeight, 10)) {
                mobileNav.classList.add(mobileNavStickyClass);
                mobileNav.style.top = 0;
                mobileNav.style.position = 'fixed';
            }
        }, 500);
    };

    const toggleDesktopNavHeaderTransparent = () => {
        if (desktopNavIsTransparent) {
            desktopNav.classList.toggle('is-fixed', window.scrollY !== 0);
            desktopNav.classList.toggle(desktopNavTransparentClass, window.scrollY === 0);
        }
    };

    const setDesktopNavHeaderTransparent = () => {
        if (desktopNavIsTransparent && scrollYPos > 0) {
            desktopNav.classList.remove(desktopNavTransparentClass);
        }
    };

    const toggleMegaMenu = link => {
        const targetMenu = document.getElementById(link.dataset.megaMenu);

        if (targetMenu.classList.contains(megaMenuExpandedClass)) {
            targetMenu.classList.remove(megaMenuExpandedClass);
            link.parentNode.classList.remove(navLinkExpandedClass);
            if (window.scrollY === 0 && desktopNav.classList.contains(hasHeroClass)) {
                desktopNav.classList.add(desktopNavTransparentClass);
                desktopNav.classList.remove('is-fixed');
            }
        } else {
            megaMenus.forEach(menu => {
                const isTarget = menu.getAttribute('id') === link.dataset.megaMenu;
                menu.classList[isTarget ? 'add' : 'remove'](megaMenuExpandedClass);
            });
            desktopNavLinkDropdown.forEach(desktopNavLink => {
                desktopNavLink.parentNode.classList.remove(navLinkExpandedClass);
            });
            link.parentNode.classList.add(navLinkExpandedClass);
            if (window.scrollY === 0 && desktopNav.classList.contains(hasHeroClass)) {
                desktopNav.classList.remove(desktopNavTransparentClass);
                desktopNav.classList.add('is-fixed');
            }
        }

        targetMenu.closest('.header__mega-menu-wrapper').addEventListener('click', e => e.target === e.currentTarget && targetMenu.classList.remove(megaMenuExpandedClass));
    };

    const megaMenuLinkAction = link => {
        megaMenuLinks.forEach(megaMenuLink => {
            const isTarget = megaMenuLink.dataset.mmsl === link.dataset.mmsl;
            megaMenuLink.classList[isTarget ? 'add' : 'remove'](megaMenuLinkActiveClass);
        });

        megaMenuSublinksContent.forEach(content => {
            const isTarget = content.getAttribute('id') === link.dataset.mmsl;
            content.classList[isTarget ? 'add' : 'remove'](megaMenuSublinksContentActiveClass);
        });
    };

    const megaMenuSublinkAction = link => {
        const megaMenuSublinksContainer = link.closest('.header__mega-menu-sublinks__content');
        const megaMenuSublinksScoped = megaMenuSublinksContainer.querySelectorAll(`.${megaMenuSublinkClass} > a`);
        const megaMenuSublinksImgScoped = megaMenuSublinksContainer.querySelectorAll(`.${megaMenuSublinksImgClass}`);

        megaMenuSublinksScoped.forEach(megaMenuSublink => {
            const isTarget = megaMenuSublink.dataset.mmslImg === link.dataset.mmslImg;
            megaMenuSublink.parentNode.classList[isTarget ? 'add' : 'remove'](megaMenuSublinkActiveClass);
        });

        megaMenuSublinksImgScoped.forEach(img => {
            const isTarget = img.getAttribute('id') === link.dataset.mmslImg;
            img.classList[isTarget ? 'add' : 'remove'](megaMenuSublinksImgActiveClass);
        });
    };

	const handleSlimlineHeader = () => {
		scrollYPos = window.scrollY;
		if (scrollYPos > desktopNavAuxLinksContainer.offsetHeight) {
			desktopNav.classList.add('header__nav--slimline');
		} else {
			desktopNav.classList.remove('header__nav--slimline');
		}
	}

    // ******************************

    // Add event listeners

    mobileNavMenuBtn.forEach(btn => btn.addEventListener('click', () => toggleMobileNav()));

    mobileNavLinkDropdown.forEach(link => {
        link.addEventListener('click', e => {
            e.preventDefault();
            toggleMobileNavLinks(e.target.parentNode);
        });
    });

    mobileNavLinkDropdownSpan.forEach(span => {
        span.addEventListener('click', e => {
            e.preventDefault();
            toggleMobileNavLinks(e.target.parentNode);
        });
    });

    desktopNavLinkDropdown.forEach(link => {
        link.addEventListener('click', e => {
            e.preventDefault();
            toggleMegaMenu(e.target);
        });
    });

    megaMenuLinks.forEach(link => link.addEventListener('mouseenter', e => megaMenuLinkAction(e.target)));
    megaMenuSublinks.forEach(link => link.addEventListener('mouseenter', e => megaMenuSublinkAction(e.target)));

    window.addEventListener('scroll', () => {
        mobileNavActive && toggleMobileNavHeaderSticky();

        if (desktopNavActive) {
			toggleDesktopNavHeaderTransparent();
			handleSlimlineHeader();
		}
    });

    window.addEventListener('load', () => {
        mobileNavActive && setMobileNavHeaderSticky();
        
		if (desktopNavActive) {
			setDesktopNavHeaderTransparent();
			handleSlimlineHeader();
		}
    });

    document.addEventListener('keyup', handleEscape);
}
