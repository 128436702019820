export default function MediaCallout(el, {
    mediaHandle,
    playButtonHandle,
	mediaModalMarkup,
	actions,
    events,
}) {
	const media = el.querySelector(mediaHandle);
    const playButton = media.querySelector(playButtonHandle);

	const toggleMp4 = () => {
		const video = document.querySelector('.modal .background-image > video');
		if (video) {
			if (video.paused) {
				video.play();
			} else {
				video.pause();
			}
		}
	}

	const loadMediaModal = () => {
        events.emit(actions.loadModal, { markup: mediaModalMarkup });
		toggleMp4();
    };

	playButton && playButton.addEventListener('click', () => {
		loadMediaModal();
	});

	events.on(actions.closeModal, () => toggleMp4());
}
