export default function ComparisonChart(el, {
    tableStickyHandleClass,
}) {
    const stickyTable = el.querySelector(`.${tableStickyHandleClass}`);
    const mobileNavHeaderHeight = getComputedStyle(document.body).getPropertyValue('--header-mobile-height');
    const mq = window.matchMedia('(min-width: 1280px)');

    let scrollYPos = window.scrollY;

    const togglePositionStickyTable = () => {
        if (!mq.matches) {
            if (window.scrollY < scrollYPos) {
                if (window.scrollY > parseInt(mobileNavHeaderHeight, 10)) {
                    stickyTable.style.top = `${mobileNavHeaderHeight}`;
                }
                scrollYPos = window.scrollY;
            } else {
                if (window.scrollY > parseInt(mobileNavHeaderHeight, 10)) {
                    stickyTable.style.top = 0;
                }
                scrollYPos = window.scrollY;
            }
        }
    };

    function handleSize() {
        if (mq.matches) {
            stickyTable.classList.add('desktop');
            stickyTable.classList.remove('mobile');
        } else {
            stickyTable.classList.add('mobile');
            stickyTable.classList.remove('desktop');
        }
    }

    window.addEventListener('load', handleSize);
    window.addEventListener('resize', handleSize);
    window.addEventListener('scroll', () => {
        stickyTable && togglePositionStickyTable();
    });
}
