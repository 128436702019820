export default function DetailsListing(el, {
    detailsItemsClass,
    modalContent,
    actions,
    events,
}) {
    const detailsItems = el.querySelectorAll(`.${detailsItemsClass} li > .image-wrapper`);

    const loadDetailsModal = data => {
        events.emit(actions.loadModal, { markup: data });
        events.emit(actions.lockScroll);
    };

    detailsItems.forEach((item, index) => item.addEventListener('click', () => loadDetailsModal(modalContent[index])));
}
