import Swiper from 'swiper';
import { Navigation, Thumbs } from 'swiper/modules';

export default function ThumbsGalleryCarousel(el, {
    carouselHandle,
    productImagesTotal,
    actions,
    events,
    galleryModalMarkup,
}) {
    const cont = el.querySelector(carouselHandle);
    const swiperMediaHandle = cont.querySelector('.swiper-media');
    const swiperThumbsHandle = cont.querySelector('.swiper-thumbs');
    const next = el.querySelector('.swiper-button-next');
    const prev = el.querySelector('.swiper-button-prev');

    const galleryColorSwatches = document.querySelectorAll('.available-colors li > img');
    const slides = swiperMediaHandle.querySelectorAll('.swiper-slide');

    if (productImagesTotal > 1) {
		const swiper = new Swiper(swiperThumbsHandle, {
			loop: true,
			slidesPerView: productImagesTotal,
			freeMode: true,
			watchSlidesProgress: true,
			spaceBetween: 1,
		});

		const swiper2 = new Swiper(swiperMediaHandle, { // eslint-disable-line
			loop: true,
			modules: [Navigation, Thumbs],
			spaceBetween: 0,
			navigation: {
				nextEl: next,
				prevEl: prev,
			},
			thumbs: {
				swiper,
			},
		});

		const updateGallerySlide = swatchPath => {
			slides.forEach(slide => {
				const slidePath = slide.querySelector('div > div.background-image--desktop').dataset.image;
				if (swatchPath === slidePath) {
					const slideIndex = slide.closest('.swiper-slide').dataset.swiperSlideIndex;
					swiper2.slideTo(slideIndex);
				}
			});
		};

		galleryColorSwatches.forEach(swatch => {
			swatch.addEventListener('click', event => {
				updateGallerySlide(event.target.dataset.image);
			});
		});
	}

    const loadGalleryModal = activeIndex => {
        events.emit(actions.loadModal, { markup: galleryModalMarkup });
        events.emit(actions.setCarouselActiveSlide, { activeSlide: activeIndex });
    };

    slides.forEach(slide => {
        slide.addEventListener('click', () => {
            loadGalleryModal(slide.dataset.swiperSlideIndex);
        });
    });
}
