import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

export default function ProductsCarousel(el, {
	swiperHandleClass,
	swiperPrevClass,
	swiperNextClass,
}) {
	const swiperHandle = el.querySelector(`.${swiperHandleClass}`);
	const swiperPrev = el.querySelector(`.${swiperPrevClass}`);
	const swiperNext = el.querySelector(`.${swiperNextClass}`);

	const swiper = new Swiper(swiperHandle, { // eslint-disable-line
		modules: [
			Navigation,
		],
		slidesPerView: 'auto',
		slidesOffsetAfter: 0,
		spaceBetween: 28,
		navigation: {
			prevEl: swiperPrev,
			nextEl: swiperNext,
		},
		breakpoints: {
            1280: {
				slidesPerView: 4.35,
            },
        }
    });
}