import Swiper from 'swiper';
import { Navigation, Scrollbar } from 'swiper/modules';

export default function HighlightsCarousel(el, {
	swiperHandleClass,
	swiperPrevClass,
	swiperNextClass,
	swiperScrollbarClass,
}) {
	const swiperHandle = el.querySelector(`.${swiperHandleClass}`);
	const swiperPrev = el.querySelector(`.${swiperPrevClass}`);
	const swiperNext = el.querySelector(`.${swiperNextClass}`);

	const swiper = new Swiper(swiperHandle, { // eslint-disable-line
		modules: [
			Navigation,
			Scrollbar,
		],
		slidesPerView: 'auto',
		spaceBetween: 16,
		navigation: {
			prevEl: swiperPrev,
			nextEl: swiperNext,
		},
		scrollbar: {
			el: `.${swiperScrollbarClass}`,
			draggable: true,
			hide: false,
		},
		breakpoints: {
			1280: {
				spaceBetween: 20,
			}
		},
    });
}