import Swiper from 'swiper';
import { Navigation, Keyboard, EffectFade } from 'swiper/modules';

export default function ImageCarousel(el, {
    carouselHandle,
    activeImage,
    actions,
    events,
}) {
    const carousel = el.querySelector(carouselHandle);
    const [prevEl, nextEl] = el.querySelectorAll(`${carouselHandle} ~ button`);

    const swiper = new Swiper(carousel, { // eslint-disable-line
        modules: [
            Navigation,
			Keyboard,
			EffectFade,
        ],
        navigation: {
            prevEl,
            nextEl,
        },
		keyboard: {
			enabled: true,
			onlyInViewport: false,
		},
		effect: 'fade',
		fadeEffect: {
			crossFade: true
		},
    });

    swiper.slideTo(activeImage);

	swiper.on('slideChange', () => events.emit(actions.carouselSlideChange));

    events.on(actions.setCarouselActiveSlide, ({ detail }) => {
        const { activeSlide } = detail;

        swiper.slideTo(activeSlide);
    });
}
