export default function Listing(el, {
    actions,
    events,
}) {
    const cards = el.querySelectorAll('.detail, .overview');
    const p = el.querySelector('p');

    events.on(actions.filterListing, ({ detail }) => {
        const { color, material, style } = detail;

        cards.forEach(c => {
            const colors = c.dataset.colors.split(',');
            const styles = c.dataset.styles.split(',');
            const materials = c.dataset.materials.split(',');

            const hasColor = color === '' || colors.includes(color);
            const hasStyle = style === '' || styles.includes(style);
            const hasMaterial = material === '' || materials.includes(material);
            const cardMatches = hasColor && hasStyle && hasMaterial;

            c.classList.toggle('visible', cardMatches);
        });

        const hasClass = Array.from(cards).some(c => c.classList.contains('visible'));

        if (hasClass) {
            p.classList.remove('visible');
        } else {
            p.classList.add('visible');
        }
    });
}
