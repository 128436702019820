import Swiper from 'swiper';
import { Scrollbar, FreeMode, Mousewheel } from 'swiper/modules';

export default async function WhereToBuy(el, {
    mapHandle,
    pinHandle,
    activeClass = 'is-active',
    dealers = [],
}) {
    /* eslint-disable no-undef */
    const { LatLngBounds } = await google.maps.importLibrary('core');
    const { Map } = await google.maps.importLibrary('maps');
    const { AdvancedMarkerElement } = await google.maps.importLibrary('marker');
    /* eslint-enable no-undef */

    const mapCont = el.querySelector(mapHandle);
    const pin = el.querySelector(pinHandle);
    const dealersListing = el.querySelectorAll('.where-to-buy__locations > li');
    const [lats, lngs] = dealers
        .reduce((acc, { lat, lng }) => [acc[0] + lat, acc[1] + lng], [0, 0]);
    const center = dealers.length
        ? { lat: lats / dealers.length, lng: lngs / dealers.length }
        : { lat: 39.8097343, lng: -98.5556199 }; // Center of the contiguous USA
    const bounds = new LatLngBounds(center);
	let zoomLevel;
	if (dealers.length === 0) {
		zoomLevel = getComputedStyle(document.querySelector('.header__nav--mobile')).display === 'block' ? 3 : 4;
	} else {
		zoomLevel = dealers.length === 1 ? 15 : 6
	}
    const map = new Map(mapCont, {
        mapId: 'a48f52d8b2ae6622',
        zoom: zoomLevel,
        center,
        disableDefaultUI: true,
        zoomControl: true,
        styles: [
            {
                featureType: 'water',
                elementType: 'geometry',
                stylers: [
                    {
                        color: '#e9e9e9',
                    },
                    {
                        lightness: 17,
                    },
                ],
            },
            {
                featureType: 'landscape',
                elementType: 'geometry',
                stylers: [
                    {
                        color: '#f5f5f5',
                    },
                    {
                        lightness: 20,
                    },
                ],
            },
            {
                featureType: 'road.highway',
                elementType: 'geometry.fill',
                stylers: [
                    {
                        color: '#ffffff',
                    },
                    {
                        lightness: 17,
                    },
                ],
            },
            {
                featureType: 'road.highway',
                elementType: 'geometry.stroke',
                stylers: [
                    {
                        color: '#ffffff',
                    },
                    {
                        lightness: 29,
                    },
                    {
                        weight: 0.2,
                    },
                ],
            },
            {
                featureType: 'road.arterial',
                elementType: 'geometry',
                stylers: [
                    {
                        color: '#ffffff',
                    },
                    {
                        lightness: 18,
                    },
                ],
            },
            {
                featureType: 'road.local',
                elementType: 'geometry',
                stylers: [
                    {
                        color: '#ffffff',
                    },
                    {
                        lightness: 16,
                    },
                ],
            },
            {
                featureType: 'poi',
                elementType: 'geometry',
                stylers: [
                    {
                        color: '#f5f5f5',
                    },
                    {
                        lightness: 21,
                    },
                ],
            },
            {
                featureType: 'poi.park',
                elementType: 'geometry',
                stylers: [
                    {
                        color: '#dedede',
                    },
                    {
                        lightness: 21,
                    },
                ],
            },
            {
                elementType: 'labels.text.stroke',
                stylers: [
                    {
                        visibility: 'on',
                    },
                    {
                        color: '#ffffff',
                    },
                    {
                        lightness: 16,
                    },
                ],
            },
            {
                elementType: 'labels.text.fill',
                stylers: [
                    {
                        saturation: 36,
                    },
                    {
                        color: '#333333',
                    },
                    {
                        lightness: 40,
                    },
                ],
            },
            {
                elementType: 'labels.icon',
                stylers: [
                    {
                        visibility: 'off',
                    },
                ],
            },
            {
                featureType: 'transit',
                elementType: 'geometry',
                stylers: [
                    {
                        color: '#f2f2f2',
                    },
                    {
                        lightness: 19,
                    },
                ],
            },
            {
                featureType: 'administrative',
                elementType: 'geometry.fill',
                stylers: [
                    {
                        color: '#fefefe',
                    },
                    {
                        lightness: 20,
                    },
                ],
            },
            {
                featureType: 'administrative',
                elementType: 'geometry.stroke',
                stylers: [
                    {
                        color: '#fefefe',
                    },
                    {
                        lightness: 17,
                    },
                    {
                        weight: 1.2,
                    },
                ],
            },
        ],
    });
    const markers = dealers.map(({ title, lat, lng }) => {
        const pinContent = pin.cloneNode(true);
        const position = { lat, lng };
        if (dealers.length > 1) {
            bounds.extend(position);
        }

        return [
            pinContent,
            new AdvancedMarkerElement({
                map,
                position,
                title,
                content: pinContent,
            }),
        ];
    });

    const urlParams = new URLSearchParams(window.location.search);
    const zipSearchForm = el.querySelector('.where-to-buy__zip-search-bar');
    const zipSearchInput = el.querySelector('.where-to-buy__zip-search-bar input');
    const radiusFilterBtn = el.querySelector('.where-to-buy__header-radius-select');
    const zipChangeBtn = el.querySelector('.where-to-buy__header-info a');
    const radiusForm = el.querySelector('.where-to-buy__header-radius-select form');
    const whereToBuyContent = el.querySelector('.where-to-buy__content');

    const focusZip = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });

        setTimeout(() => {
            zipSearchInput.focus();
            zipSearchForm.classList.add('where-to-buy__zip-search-bar--focus');
        }, 500);

        setTimeout(() => {
            window.addEventListener('scroll', () => {
                zipSearchForm.classList.remove('where-to-buy__zip-search-bar--focus');
            });
        }, 600);
    };

    const swiper = new Swiper('.where-to-buy__locations-container .swiper', {
        modules: [Scrollbar, FreeMode, Mousewheel],
        direction: 'vertical',
        slidesPerView: 'auto',
        freeMode: true,
        scrollbar: {
            el: '.swiper-scrollbar',
            hide: false,
        },
        mousewheel: true,
    });

    dealersListing.forEach((l, i) => {
        l.onmouseenter = () => {
            const [p] = markers[i];
            p.classList.add(activeClass);
            l.classList.add(activeClass);
        };
        l.onmouseleave = () => {
            const [p] = markers[i];
            p.classList.remove(activeClass);
            l.classList.remove(activeClass);
        };
    });
    if (dealers.length > 1) {
        map.fitBounds(bounds);
    }
    markers.forEach(([p, m], i) => {
        m.addListener('click', () => {
            if (
                i === markers.length - 1
                && swiper.slides[i].getBoundingClientRect().bottom > window.innerHeight
            ) {
                swiper.el.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }
            swiper.slideTo(i);
            markers.forEach(([pp]) => {
                pp.classList.remove(activeClass);
            });
            p.classList.add(activeClass);
            dealersListing.forEach((l, ii) => {
                l.classList.toggle(activeClass, i === ii);
            });
        });
    });

    radiusFilterBtn.addEventListener('click', () => {
        radiusFilterBtn.classList.toggle('where-to-buy__header-radius-select--active');
    });

    radiusForm.addEventListener('change', () => {
        radiusForm.submit();
    });

    zipChangeBtn.addEventListener('click', e => {
        e.preventDefault();
        focusZip();
    });

    if (urlParams.has('zip') || urlParams.has('radius')) {
        setTimeout(() => {
            whereToBuyContent.scrollIntoView({ behavior: 'smooth' });
        }, 500);
    }
}
