// Framework
import pop from './components';

// Components
import AvailableColors from './components/available-colors';
import BrochureListing from './components/brochure-listing';
import CollectionListing from './components/collection-listing';
import ComparisonChart from './components/comparison-chart';
import DetailsCarousel from './components/details-carousel';
import DetailsListing from './components/details-listing';
import Form from './components/form';
import FormField from './components/form-field';
import Header from './components/header';
import HighlightsCarousel from './components/highlights-carousel';
import IframeWrapper from './components/iframe-wrapper';
import ImageCarousel from './components/image-carousel';
import InspirationGallery from './components/inspiration-gallery';
import Listing from './components/listing';
import ListingDownloads from './components/listing-downloads';
import Main from './components/main';
import MediaCallout from './components/media-callout';
import Modal from './components/modal';
import PrimaryHero from './components/primary-hero';
import ProductsCarousel from './components/products-carousel';
import Resources from './components/resources';
import ResourcesCard from './components/resources-card';
import TestimonialsCarousel from './components/testimonials-carousel';
import ThumbsGalleryCarousel from './components/thumbs-gallery-carousel';
import TimelineCarousel from './components/timeline-carousel';
import VideoYoutube from './components/video-youtube';
import WhereToBuy from './components/where-to-buy';

// Define map of component handles to component classes
const classMap = {
    'available-colors': AvailableColors,
    'brochure-listing': BrochureListing,
    'collection-listing': CollectionListing,
    'comparison-chart': ComparisonChart,
    'details-carousel': DetailsCarousel,
	'details-listing': DetailsListing,
    form: Form,
    'form-field': FormField,
    header: Header,
    'highlights-carousel': HighlightsCarousel,
    'image-carousel': ImageCarousel,
	'iframe-wrapper': IframeWrapper,
    'inspiration-gallery': InspirationGallery,
    main: Main,
    'media-callout': MediaCallout,
    modal: Modal,
    'primary-hero': PrimaryHero,
    listing: Listing,
    'listing-downloads': ListingDownloads,
    'products-carousel': ProductsCarousel,
	resources: Resources,
    'resources-card': ResourcesCard,
    'testimonials-carousel': TestimonialsCarousel,
    'thumbs-gallery-carousel': ThumbsGalleryCarousel,
    'timeline-carousel': TimelineCarousel,
    'video-youtube': VideoYoutube,
    'where-to-buy': WhereToBuy,
};

// Define all actions/commands that components pub/sub
const actions = {
    // Action events
    scrollTo: 'scroll-to',
    lockScroll: 'lock-scroll',
    unlockScroll: 'unlock-scroll',
    openModal: 'open-modal',
    closeModal: 'close-modal',
    loadModal: 'load-modal',
    showFieldError: 'show-field-error',
    setCarouselActiveSlide: 'set-carousel-active-slide',
    carouselSlideChange: 'carousel-slide-change',
    filterListing: 'filter-listing',
};

// Event handler functions
function handleDOMConentLoaded() {
    function cb() {
        // Anchor links
        document.querySelectorAll('a[href^="#"]').forEach(l => {
            l.addEventListener('click', e => {
                e.preventDefault();

                const classesToExclude = ['back-to-top', 'tab'];
                if (classesToExclude.some(className => l.classList.contains(className))) {
                    return;
                }

                // Remove the '#' from the href
                const targetId = l.getAttribute('href').substring(1);

                const target = document.getElementById(targetId);

                if (target) {
                    target.scrollIntoView({ behavior: 'smooth' });
                }
            });
        });
    }

    pop({ classMap, actions, cb });
}

// Add event listeners
document.addEventListener('DOMContentLoaded', handleDOMConentLoaded);
